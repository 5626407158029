import React from 'react';

const HeroQuote = () => {
  return (
    <>
      <div className="container mx-auto py-8">
        <div className="flex flex-col p-12 bg-zinc-50 border-l-4 border-gray-500">
          <p className="font-serif text-4xl">
            “To try and fail is at least to learn; to fail to try is to suffer
            the inestimable loss of what might have been.”
          </p>
          <p className="text-gray-500 text-sm mt-8">
            - Brian Christian, Algorithms To Live By: The Computer Science of
            Human Decisions
          </p>
        </div>
      </div>
    </>
  );
};

export default HeroQuote;
