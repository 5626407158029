import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import ArrowLink from '../../components/ArrowLink';

const HomeHero = () => {
  return (
    <>
      <div className="container mx-auto py-8">
        <div className="grid grid-cols-1 pt-4 lg:grid-cols-2 gap-8 place-items-center">
          <div className="flex flex-col justify-center">
            <div>
              <h1 className="text-4xl tracking-tight font-bold text-gray-900 sm:text-5xl md:text-6xl">
                <span className="xl:inline">The story so far...</span>
              </h1>
            </div>
            <div>
              <p className="mt-3 text-base text-gray-900 sm:mt-5 sm:text-lg md:mt-5 md:text-xl">
                Recent software engineering graduate with more than 3 years of
                industry experience from leading companies such as: Google,
                Shopify, Wealthsimple, and Riot Games. I have participated in
                over 15+ hackathons / startup competitions, winning 7 titles and
                raising $30,000+ over the course of 2 years. I consider myself a
                learning machine and can approach any problem intuitively. I am
                a designer, developer, and most importantly a doer.
              </p>
            </div>
            <div className="mt-5 sm:mt-8 flex justify-start">
              <ArrowLink to="/blog" text="Read more" />
            </div>
          </div>
          <div className="w-full ring-4 ring-gray-900 p-0.5 rounded-full order-first lg:order-last flex aspect-square align-middle">
            <StaticImage
              src="../../assets/portrait.jpg"
              alt="hero image"
              placeholder="blurred"
              objectFit="contain"
              style={{
                borderRadius: '50%',
              }}
              imgStyle={{
                borderRadius: '50%',
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default HomeHero;
