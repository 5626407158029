import React from 'react';

const HomeProjects = () => {
  return (
    <>
      <div className="container mx-auto pt-8">
        <div className="flex flex-col">
          <p className="text-3xl tracking-tight font-bold text-gray-900 sm:text-4xl md:text-5xl">
            Recent projects.
          </p>
          <p className="mt-4">
            During my free time, I like to explore around with new ideas and
            emerging tech. My hope is to evetually contribute to open-source
            full time and give back to the community.
          </p>
        </div>
        <div className="h-0.5 w-full bg-gray-500 my-4" />
      </div>
    </>
  );
};

export default HomeProjects;
